import clsx from 'clsx'

type PlaceProps = {
  className?: string
  value: number
  isUser?: boolean
}

const Place: React.FC<PlaceProps> = (props) => {
  const { className, value, isUser } = props

  const valueNum = Number(value) || 0
  const rootClassName = clsx(
    className,
    'size-10 overflow-hidden rounded-3 border-2 border-182D47 pl-[3px] font-bold leading-none text-182D47',
    {
      'text-px-10': valueNum > 9999,
      'text-px-12': valueNum > 999 && valueNum <= 9999,
      'text-px-14': valueNum <= 999,
      'bg-[#FFFF77]': valueNum === 1 || (isUser && valueNum > 10),
      'bg-[#FDFBF6]': valueNum === 2,
      'bg-[#FFCB8C]': valueNum === 3,
      'bg-[#FFFFFF]': !valueNum || valueNum > 3,
    },
  )

  const innerClassName = clsx('grid size-full place-items-center rounded-l-3', className, {
    'bg-[#FFBA09]': valueNum === 1 || (isUser && valueNum > 10),
    'bg-[#D5D3CE]': valueNum === 2,
    'bg-[#DB7124]': valueNum === 3,
    'bg-[#F5F3E9]': !valueNum || valueNum > 3,
  })

  return (
    <div className={rootClassName}>
      <div className={innerClassName}>{valueNum}</div>
    </div>
  )
}

export default Place
