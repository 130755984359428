import { SpoolSdk, getSepoliaConfig, getMainnetConfig } from '@spool.fi/spool-v2-sdk'
import { getEthersProvider, getEthersSigner } from 'wallet'
import { constants } from 'helpers'

const graphApiKey = process.env.NEXT_PUBLIC_GRAPH_API_KEY

if (!graphApiKey) {
  throw new Error('GRAPH_API_KEY not provided')
}

const endDate = new Date('2024-12-17T00:00:00.000Z').getTime()

export const yelayNetworkName = constants.isDev ? 'sepolia' : 'mainnet'
export const yelayVaults = constants.isDev
  ? [
      {
        address: '0x09d17590d67bec9129f329f950aee874ec70d599',
        jettons: 7,
        isPrimary: true,
        withFastWithdrawal: false,
        endDate,
      },
      {
        address: '0x92b13936d5d03386953086a228bc8d96690cbf95',
        jettons: 6,
        isPrimary: false,
        withFastWithdrawal: true,
        endDate,
      },
    ]
  : [
      {
        address: '0x67f85eff39229e5f5f9cbe1ec82e5f6401b2232c',
        jettons: 7,
        isPrimary: true,
        withFastWithdrawal: false,
        endDate,
      },
      {
        address: '0x94454d2862a2ef5567b6d8b38e38d69360f85fe2',
        jettons: 6,
        isPrimary: false,
        withFastWithdrawal: true,
        endDate,
      },
    ]

export type PoolBaseDate = (typeof yelayVaults)[number]

export type Token = {
  decimals: number
  address: Address
  name: string
  symbol: string
}

export const tokens: Token[] = [constants.tokensBySymbol.USDC, constants.tokensBySymbol.DAI]

const getConfig = () => {
  if (constants.isDev) {
    // `https://gateway-testnet-arbitrum.network.thegraph.com/api/${graphApiKey}/subgraphs/id/J5s1Q5ECEuvcyr8hfCVJxdebmwiQTGWbNGXu8GLfnSBj`,
    return getSepoliaConfig(
      'https://subgraph.satsuma-prod.com/49eb322da234/solidant/spool-v2-sepolia/api',
    )
  }

  return getMainnetConfig(
    `https://gateway-arbitrum.network.thegraph.com/api/${graphApiKey}/subgraphs/id/41A5vZJ2fypeqtD8o5rD1HB7GjVkvP55NR3EeZDXkk2s`,
  )
}

export const yelayConfig = getConfig()

export const getYelayViewSDK = () => {
  const provider = getEthersProvider({ chainId: constants.appChainId })

  return new SpoolSdk(yelayConfig, provider)
}

export const getYelaySignerSDK = async () => {
  const provider = await getEthersSigner({ chainId: constants.appChainId })

  return new SpoolSdk(yelayConfig, provider!)
}
