export default {
  title: {
    en: 'Where fun meets fortune!',
  },
  showBox: {
    en: 'Show giftboxes',
  },
  cards: [
    {
      title: {
        en: 'Deposit',
      },
      text: {
        en: `
          Take the first step into the world of Piggy Banks and start earning effortlessly. 
          Sit back, relax, and watch your Jettons grow.
        `,
      },
    },
    {
      title: {
        en: 'Open the GiftBox',
      },
      text: {
        en: `
          What’s waiting inside?<br/>Only one way to find out!<br/>
          Unbox a legendary surprise and enjoy the thrill of premium drops.
          We guarantee fairness, so the fun never stops.
        `,
      },
    },
    {
      title: {
        en: 'Get Drops',
      },
      text: {
        en: `
          Get your OWN drop every day.<br/>Forget the hunt for the right one — 
          we've been doing this for a while. Now it's your turn, and we'll show you the way!
        `,
      },
    },
  ],
}
