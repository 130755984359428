'use client'

import { motion } from 'framer-motion'
import Image from 'next/image'
import { Icon, IconName } from 'components/ui'
import { Button, buttonMessages } from 'components/inputs'
import redPigMobile from 'public/images/pigs/red-small.png'
import redPig from 'public/images/pigs/red.png'
import { Message } from '@locmod/intl'
import messages from './messages'
import clsx from 'clsx'
import { openModal } from '@locmod/modal'
import ConnectButtonWrapper from 'compositions/ConnectButtonWrapper/ConnectButtonWrapper'
import { yelayVaults } from 'yelay'
import { constants } from 'helpers'

type EarnTokensSectionProps = {
  className?: string
}

const benefits: { iconName: IconName; text: Intl.Message }[] = [
  {
    iconName: 'ui/deposit-and-withdrawal',
    text: messages.deposit,
  },
  {
    iconName: 'ui/reward',
    text: messages.reward,
  },
  {
    iconName: 'ui/money',
    text: messages.money,
  },
  {
    iconName: 'ui/safety',
    text: messages.safety,
  },
  {
    iconName: 'ui/code',
    text: messages.code,
  },
]

const EarnTokensSection: React.FC<EarnTokensSectionProps> = ({ className }) => {
  return (
    <motion.section
      id="earn"
      className={clsx(className, 'scroll-m-5')}
      variants={constants.baseAnimationVariants.container}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.6 }}
    >
      <div className="bg-[#11E699] p-4 pt-6 lg:hidden">
        <Message
          value={messages.title}
          tag="h2"
          className="w-2/3 font-source-serif text-px-32 leading-120p"
        />
        <div className="relative mt-4 flex flex-col gap-4 rounded-5 bg-accent-yellow p-4 pt-6 lg:rounded-10 lg:p-8">
          <Image
            src={redPigMobile}
            alt="coins pig"
            className="absolute -right-2 -top-44 h-auto w-36"
          />
          <div className="flex items-center gap-2">
            <Icon name="ui/coin" className="size-15" />
            <div>
              <p className="text-px-24 font-light leading-120p">Up to</p>
              <p className="text-px-32 font-black leading-120p">
                {yelayVaults[0].jettons * 1000} Jettons
              </p>
              <p className="text-px-13 font-bold leading-130p">per $1000 daily!</p>
            </div>
          </div>
          <div className="rounded-5 bg-white/40 p-4">
            <Message
              value={messages.advantages}
              className="text-px-14 font-bold leading-150p text-black/70"
            />
            <ul className="mt-4 flex flex-col gap-4">
              {benefits.map(({ iconName, text }) => (
                <li key={iconName} className="flex items-center gap-x-2">
                  <div className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-accent2/40">
                    <Icon name={iconName} className="size-6" />
                  </div>
                  <Message value={text} className="text-px-13 leading-130p" />
                </li>
              ))}
            </ul>
          </div>
          <ConnectButtonWrapper>
            <Button
              title={buttonMessages.makeDeposit}
              styling="second"
              size="big"
              leftIcon="ui/pig"
              className="justify-center"
              onClick={() => openModal('DepositModal')}
            />
          </ConnectButtonWrapper>
        </div>
      </div>
      <div className="px-10 mb:hidden">
        <Message
          value={messages.title}
          tag="h2"
          className="text-center font-source-serif text-px-40 leading-14 text-grey-800"
        />
        <div className="relative mt-7 flex justify-end rounded-8 bg-[#11E699] p-5">
          <motion.div
            variants={constants.baseAnimationVariants.itemY}
            className="absolute bottom-4 left-20 h-[450px] w-[280px]"
          >
            <Image src={redPig} alt="" className="size-full" />
          </motion.div>
          <motion.div
            variants={constants.baseAnimationVariants.itemY}
            className="flex w-[900px] justify-between rounded-10 bg-accent-yellow p-8"
          >
            <div className="flex flex-col justify-center gap-6">
              <Message
                value={messages.pool}
                tag="p"
                className="font-source-serif text-px-32 leading-150p mb:hidden"
              />
              <div>
                <p className="text-px-40 font-black leading-120p">
                  Up to <Icon name="ui/coin" className="inline size-15 flex-none" />{' '}
                  {yelayVaults[0].jettons * 1000} Jettons
                </p>
                <p className="font-medium leading-150p">per $1000 daily!</p>
              </div>
              <div>
                <ConnectButtonWrapper>
                  <Button
                    title={buttonMessages.makeDeposit}
                    styling="second"
                    size="big"
                    leftIcon="ui/pig"
                    onClick={() => openModal('DepositModal')}
                  />
                </ConnectButtonWrapper>
              </div>
            </div>
            <motion.div
              variants={constants.baseAnimationVariants.itemY}
              className="w-[292px] rounded-5 bg-white/40 p-6"
            >
              <Message
                value={messages.advantages}
                className="text-px-14 font-bold leading-150p text-black/70"
              />
              <ul className="mt-6 flex flex-col gap-4">
                {benefits.map(({ iconName, text }) => (
                  <li key={iconName} className="flex items-center gap-x-2">
                    <div className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-accent2/40">
                      <Icon name={iconName} className="size-6" />
                    </div>
                    <Message value={text} className="text-px-13 leading-130p" />
                  </li>
                ))}
              </ul>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </motion.section>
  )
}

export default EarnTokensSection
