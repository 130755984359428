'use client'

import { motion } from 'framer-motion'
import { Button, buttonMessages } from 'components/inputs'
import suitcase from 'public/images/treasure-suitcase.png'
import ConnectButtonWrapper from 'compositions/ConnectButtonWrapper/ConnectButtonWrapper'
import { openModal } from '@locmod/modal'
import Image from 'next/image'
import boostIncome from 'public/images/boost-your-income.png'
import boostIncomeMobile from 'public/images/boost-your-income-small.png'
import { constants } from 'helpers'

type IncreaseIncomeBannerProps = {
  className?: string
}

const BoostIncomeBanner: React.FC<IncreaseIncomeBannerProps> = ({ className }) => {
  return (
    <motion.section
      className={className}
      variants={constants.baseAnimationVariants.container}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
    >
      <div className="bg-tile-pattern mt-16 flex flex-col rounded-10 pb-4 lg:h-[368px] lg:flex-row lg:pl-[115px]">
        <motion.div
          variants={constants.baseAnimationVariants.itemY}
          className="-mt-16 lg:order-last lg:h-[420px]"
        >
          <Image src={suitcase} alt="a suitcase of treasure" className="lg:w-auto" />
        </motion.div>
        <motion.div variants={constants.baseAnimationVariants.itemY} className="lg:-mt-[76px]">
          <Image
            src={boostIncomeMobile}
            alt="boost your income"
            className="mx-auto h-22 w-auto lg:hidden"
          />
          <Image src={boostIncome} alt="boost your income" className="mb:hidden" />
          <div className="px-4">
            <ConnectButtonWrapper>
              <Button
                title={buttonMessages.makeDeposit}
                styling="second"
                size="big"
                leftIcon="ui/pig"
                className="mt-2 justify-center mb:w-full lg:mt-6"
                onClick={() => openModal('DepositModal')}
              />
            </ConnectButtonWrapper>
          </div>
        </motion.div>
      </div>
    </motion.section>
  )
}

export default BoostIncomeBanner
