'use client'

import messages from './messages'
import Image from 'next/image'
import { Message } from '@locmod/intl'
import { Button, buttonMessages } from 'components/inputs'
import heroBannerPigMobile from 'public/images/pigs/money-bath-small.png'
import heroBannerHeadline from 'public/images/hero/hero-banner-headline.png'
import heroBannerCover from 'public/images/hero/hero-banner-cover.png'
import heroBannerPig from 'public/images/pigs/money-bath.png'
import bgNoise from 'public/images/bg-gradient-noise.png'
import clsx from 'clsx'
import ConnectButtonWrapper from 'compositions/ConnectButtonWrapper/ConnectButtonWrapper'
import { openModal } from '@locmod/modal'
import { motion } from 'framer-motion'
import { constants } from 'helpers'

type HeroSectionProps = {
  className?: string
}

const bgStyle = { backgroundImage: `url(${bgNoise.src})` }

const HeroSection: React.FC<HeroSectionProps> = ({ className }) => {
  return (
    <motion.section
      className={clsx(
        className,
        'mx-auto flex w-full max-w-[1100px] items-center gap-4 text-center mb:flex-col mb:px-4',
      )}
      variants={constants.baseAnimationVariants.container}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
    >
      <motion.div
        className="bg-size-100p absolute -top-16 left-1/2 -z-10 h-[500px] -translate-x-1/2 -rotate-6 bg-repeat-x lg:top-0"
        style={bgStyle}
        variants={{
          hidden: {
            width: 0,
          },
          visible: {
            width: '150vw',
          },
        }}
      />
      <motion.div
        variants={constants.baseAnimationVariants.itemY}
        className="relative z-0 w-fit flex-none mb:ml-4 mb:h-[11.25rem] lg:order-last lg:w-[415px]"
      >
        <Image src={heroBannerCover} alt="" className="z-0 h-full w-auto lg:h-[448px]" />
        <Image
          src={heroBannerPigMobile}
          alt="pig in bath full of money"
          className="absolute top-1 z-0 h-36 w-auto max-w-none animate-float-down lg:hidden"
          draggable="false"
        />
        <Image
          src={heroBannerPig}
          alt="pig in bath full of money"
          className="absolute top-6 z-0 h-[360px] w-auto animate-float-down mb:hidden"
          draggable="false"
        />
      </motion.div>
      <motion.div
        variants={constants.baseAnimationVariants.itemY}
        className="relative z-0 mb:-mt-15 lg:text-left"
      >
        <h1>
          <Message value={messages.title} tag="span" className="sr-only" html />
          <Image
            src={heroBannerHeadline}
            alt=""
            className="w-auto lg:h-[191px]"
            draggable="false"
          />
        </h1>
        <Message
          value={messages.description}
          tag="p"
          className="mt-2.5 text-balance text-px-16 text-grey-800 lg:mt-4 lg:text-px-18"
          html
        />
        <ConnectButtonWrapper>
          <Button
            title={buttonMessages.makeDeposit}
            styling="second"
            size="s"
            leftIcon="ui/pig"
            className="mt-4 lg:btn-size-big"
            onClick={() => openModal('DepositModal')}
          />
        </ConnectButtonWrapper>
      </motion.div>
    </motion.section>
  )
}

export default HeroSection
