import { useQuery } from '@tanstack/react-query'
import { restApi } from 'helpers'
import { getTokenSymbolFromName } from 'helpers/getters'

const fetch = async () => {
  const { data } = await restApi.get<{
    items: GameHistoryItem[]
    count: number
    pages: number
  }>('/games')

  return data.items.map((item) => ({
    ...item,
    rewards: item.rewards.map((reward) => ({
      ...reward,
      coin: reward.coin
        ? { ...reward.coin, name: getTokenSymbolFromName(reward.coin.name) }
        : reward.coin,
    })),
  }))
}

export const useGameHistory = () => {
  return useQuery({
    queryKey: ['games'],
    queryFn: fetch,
    refetchInterval: 60000,
  })
}
