'use client'

import clsx from 'clsx'
import { shortenAddress, constants, toLocaleString } from 'helpers'
import Image from 'next/image'
import { Message } from '@locmod/intl'
import messages from './messages'
import { useGameHistory } from 'hooks/useGameHistory'
import { Icon } from 'components/ui'

type LastWinsListProps = {
  className?: string
}

type WinnerCardProps = {
  rewards: BoxReward[]
  cashback: number | undefined
  address: string
}

const findMostValuable = (rewards: BoxReward[]): BoxReward => {
  return rewards.reduce((prev, item) => {
    if (!prev) {
      return item
    }

    const prevValue = prev.quantity * prev.coin.price
    const currValue = item.quantity * item.coin.price

    return currValue > prevValue ? item : prev
  }, null as any)
}

const WinnerCard: React.FC<WinnerCardProps> = ({ rewards, cashback, address }) => {
  const mostValuableReward = rewards?.length ? findMostValuable(rewards) : null

  if (!mostValuableReward && !cashback) {
    return null
  }

  const icon = mostValuableReward
    ? constants.tokensBySymbol[mostValuableReward.coin.name]?.icon
    : 'ui/coin'

  return (
    <div
      className={clsx(
        'dashed-hover-border flex min-w-60 shrink-0 items-center gap-4 rounded-6 bg-[#4079d5] p-4 text-white hover:bg-[#4a83df] lg:min-w-[292px]',
      )}
    >
      {mostValuableReward?.coin.picture ? (
        <Image src={mostValuableReward.coin.picture} alt="" className="size-12 rounded-full" />
      ) : (
        <Icon name={icon || 'ui/no-icon'} className={clsx('size-12', !icon && 'text-white/50')} />
      )}
      <div className="flex flex-col">
        <span className="space-x-1.5 whitespace-nowrap text-px-12 font-medium uppercase leading-150p">
          <span>{shortenAddress(address, 4)}</span>
          <Message value={messages.won} />
        </span>
        <span className="text-px-20 font-bold uppercase leading-150p">
          {mostValuableReward
            ? `${toLocaleString(mostValuableReward.quantity, { cutFractionalZero: true, digits: 2 })} ${mostValuableReward.coin.name}`
            : `${toLocaleString(cashback!, { digits: 0 })} Jettons`}
        </span>
      </div>
    </div>
  )
}

const LastWinsList: React.FC<LastWinsListProps> = ({ className }) => {
  const { data, isFetching } = useGameHistory()

  if (!isFetching && !data?.length) {
    return null
  }

  return (
    <div
      className={clsx(
        className,
        'space-y-4 lg:mx-10 lg:rounded-6 lg:border lg:border-dashed lg:border-white lg:py-4',
      )}
    >
      <Message
        value={messages.title}
        tag="h4"
        className="px-4 font-source-serif text-px-24 leading-130p text-grey-800"
      />
      <div className="no-scrollbar flex gap-2 overflow-x-auto px-4 lg:gap-3">
        {!data?.length ? (
          <div className="bone h-[5.125rem] min-w-60 rounded-6 bg-[#4079d5] lg:min-w-[292px]" />
        ) : (
          data
            ?.slice(0, 10)
            .map(({ id, user, rewards, cashback }) => (
              <WinnerCard key={id} rewards={rewards} cashback={cashback} address={user.wallet} />
            ))
        )}
      </div>
    </div>
  )
}

export default LastWinsList
