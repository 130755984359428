const USDT = 'USDT'
const USDC = 'USDC'
const WIF = 'WIF'

export const getTokenSymbolFromName = (name: string) => {
  const pre = name.replace(/\s+/g, '').toUpperCase()

  if (pre.includes(USDT)) {
    return USDT
  }

  if (pre.includes(USDC)) {
    return USDC
  }

  if (pre.includes(WIF)) {
    return WIF
  }

  return pre
}
