'use client'

import clsx from 'clsx'
import LeaderboardTable from 'compositions/LeaderboardTable/LeaderboardTable'
import messages from './messages'
import { Message } from '@locmod/intl'

type LeaderboardSectionProps = {
  className?: string
}

const LeaderboardSection: React.FC<LeaderboardSectionProps> = ({ className }) => {
  return (
    <section className={clsx(className, 'mb:px-4')}>
      <Message value={messages.title} tag="h2" className="text-h2 text-center text-grey-800" />
      <Message
        value={messages.description}
        tag="p"
        className="text-main mt-4 text-center text-grey-800"
      />
      <LeaderboardTable className="mt-8" />
    </section>
  )
}

export default LeaderboardSection
