import { useQuery } from '@tanstack/react-query'
import { restApi } from 'helpers'
import { useAuthContext } from 'contexts'
import { useProfileMe } from 'hooks'
import { useMemo } from 'react'

const useLeaderboard = () => {
  const { address } = useAuthContext()

  const { data: userData, isFetching: isFetchingUser } = useProfileMe()
  const { data: leaderboardData, isFetching } = useQuery({
    queryKey: ['leaderboard', address],
    queryFn: async () => {
      const [leaderboardData, userApiData] = await Promise.allSettled([
        // TODO there is no endpoint for leaderboard - added on 07.10.2024 by sonatskiy
        restApi.get<UserData[]>('/leaderboard?sortField=profit_passive'),
        address ? restApi.get<{ position: number }>('/profile/top?sortField=profit_passive') : Promise.reject(),
      ])

      if (leaderboardData.status === 'rejected') {
        return null
      }

      const userPosition = userApiData.status === 'fulfilled' ? userApiData.value.data.position : 0
      const userIndex = userPosition - 1

      const top = leaderboardData.value.data.map((item, index) => ({
        ...item,
        isUser: userIndex === index,
      }))

      const isUserOutOfTop = userPosition > 10

      return {
        top,
        isUserOutOfTop,
        userPosition,
      }
    },
    refetchOnWindowFocus: false,
  })

  const data = useMemo(() => {
    if (!leaderboardData) {
      return null
    }

    return {
      top: leaderboardData.top,
      isUserOutOfTop: leaderboardData.isUserOutOfTop,
      userPosition: leaderboardData.userPosition,
      userData,
    }
  }, [userData, leaderboardData])

  return {
    data,
    isFetching: isFetchingUser || isFetching,
  }
}

export default useLeaderboard
